<template>
  <div class="reseller-info-detail">
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-body>
        <v-tabs
          :tab-items="tabItems"
          :active.sync="tabIdx"
        />
      </b-card-body>
    </b-card>
    <!-- Basic Info -->
    <basic-info v-show="tabIdx == tabItems.indexOf('Basic Info')" :tab-idx="tabIdx" :reseller-id="reseller_id" />
    <!-- Buyin Rate Config -->
    <buyin-rate-config v-show="tabIdx == tabItems.indexOf('Buyin Rate Config')" :tab-idx="tabIdx" :reseller-id="reseller_id" />
    <!-- KYC Info -->
    <kyc-info v-show="tabIdx == tabItems.indexOf('KYC Info')" :tab-idx="tabIdx" :reseller-id="reseller_id" />
    <!-- Settlement Config -->
    <settlement-config v-show="tabIdx == tabItems.indexOf('Settlement Config')" :tab-idx="tabIdx" :reseller-id="reseller_id" />
  </div>
</template>
<script>
import {
  defineComponent, reactive, getCurrentInstance,
} from '@vue/composition-api'
import {
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import store from '@/store'
import resellerStoreModule from '@/pagsmile/resellerStoreModule'
import VTabs from './component/VTabs.vue'
import BasicInfo from './component/BasicInfo.vue'
import BuyinRateConfig from './component/BuyinRateConfig.vue'
import KycInfo from './component/KYCInfo.vue'
import SettlementConfig from './component/SettlementConfig.vue'

export default defineComponent({
  setup() {
    const RESELLER_KYC_STORE_MODULE_NAME = 'resellerKyc'
    if (!store.hasModule(RESELLER_KYC_STORE_MODULE_NAME)) {
      store.registerModule(RESELLER_KYC_STORE_MODULE_NAME, resellerStoreModule)
    }
    const { proxy } = getCurrentInstance()
    // eslint-disable-next-line camelcase
    const { reseller_id, from = '' } = proxy.$route.query
    let tabIdx = '0'
    const tabItems = reactive(['Basic Info', 'Buyin Rate Config', 'KYC Info', 'Settlement Config'])
    if (from === 'buyin-rate-config') {
      tabIdx = '1'
    } else if (from === 'kycreview') {
      tabIdx = '2'
    } else if (from === 'settlement-ment') {
      tabIdx = '3'
    }
    return {
      reseller_id,
      tabIdx,
      tabItems,
    }
  },
  components: {
    VTabs,
    BasicInfo,
    BuyinRateConfig,
    KycInfo,
    SettlementConfig,

    BCard,
    BCardBody,
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.reseller-info-detail {
  margin: 30px;
}
</style>
