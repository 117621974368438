<template>
  <div>
    <b-card>
      <b-overlay
        :show="isSettlementLoading"
        spinner-variant="primary"
        variant="light"
        opacity="0.7"
        rounded="sm"
      >
        <b-card-header>
          <b-card-title>Settlement Config</b-card-title>
          <b-button
            variant="primary"
            class="mr-4"
            @click="createSettlementConfig"
          >
            Create
          </b-button>
        </b-card-header>
        <b-card-header><b-card-title>RevShare Report</b-card-title></b-card-header>
        <b-table
          v-if="isShowSettlementTable"
          ref="refSettlementTable"
          class="position-relative"
          :items="tableItems"
          :per-page="perPage"
          :current-page="currentPage"
          responsive
          :fields="tableColumns"
          primary-key="uid"
          show-empty
          empty-text="No matching records found"
        >
          <!-- Column: Status -->
          <template #cell(status)="data">
            <div class="text-nowrap">
              <b-form-checkbox
                :checked="data.item.status === 'ACTIVE'"
                name="check-button"
                switch
                inline
                @change="changeRevShareStatus(data.item)"
              />
            </div>
          </template>
          <!-- Column: exchangeRate 汇率 -->
          <template #cell(exchangeRate)="data">
            <div class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="checkRate(data.item.id)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span class="align-middle">Check</span>
              </b-button>
            </div>
          </template>
          <!-- Column: processingFee 费率 显示历史费率的配置 -->
          <template #cell(processingFee)="data">
            <div class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="checkFee(data.item.id)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span class="align-middle">Check</span>
              </b-button>
            </div>
          </template>
          <!-- Column: file -->
          <template #cell(report_url)="data">
            <div class="settlement-template-box">
              <div class="text-nowrap mr-1">
                <a :href="data.item.report_url" target="_blank" rel="file">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    <span class="align-middle">Download</span>
                  </b-button>
                </a>
              </div>
              <div class="upload-button-box">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="upload-button"
                >
                  Upload
                </b-button>
                <input
                  id="cover-page"
                  type="file"
                  class="upload-file-input"
                  @change="uploadFileChange(arguments[0], data.item)"
                >
              </div>
            </div>
          </template>
          <!-- Column: action -->
          <template #cell(action)="data">
            <div class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="editSettlementConfig(data.item)"
              >
                <span class="align-middle">Edit</span>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <!-- modal -->
    <b-modal
      ref="settlement-create-modal"
      :title="settlementTitle"
      :ok-title="settlementOkTitle"
      cancel-variant="outline-secondary"
      size="xl"
      hide-footer
      @hidden="hiddenResetModal"
    >
      <b-overlay
        :show="isCreateLoading"
        spinner-variant="primary"
        variant="light"
        opacity="0.7"
        rounded="sm"
      >
        <!-- shape="square" circle -->
        <form-wizard
          v-if="showWizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="tab"
          finish-button-text="Submit"
          next-button-text="Next"
          back-button-text="Previous"
          class="mb-3"
          :start-index="wizardIndex"
          step-size="lg"
          @on-complete="wizardSubmitted"
          @on-change="wizardChange"
          @update:startIndex="wizardUpdateIndex"
        >
          <!-- 分润结算配置 -->
          <tab-content
            title="1. Revenure share config"
            :before-change="validationProfitSharing"
          >
            <profit-sharing-config ref="profit-sharing" :reseller-id="resellerId" :wizard-index="wizardIndex" :revenue-id="revenueId" />
          </tab-content>
          <tab-content
            title="2. Manual adjustments"
            :before-change="validationManualAdjustment"
          >
            <manual-adjustment ref="manual-adjust" :reseller-id="resellerId" :wizard-index="wizardIndex" :revenue-id="revenueId" />
          </tab-content>
          <tab-content
            title="3. Exchange rate config"
            :before-change="validationExchangeRate"
          >
            <exchange-rate-configuration ref="exchange-rate" :reseller-id="resellerId" :wizard-index="wizardIndex" :revenue-id="revenueId" />
          </tab-content>
          <tab-content
            title="4. Description"
            :before-change="validationDescpConfiguration"
          >
            <descp-configuration ref="descp-config" :reseller-id="resellerId" :wizard-index="wizardIndex" :revenue-id="revenueId" />
          </tab-content>
          <b-button
            slot="prev"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50"
            />
            Back
          </b-button>
          <b-button
            slot="next"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            Next
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </b-button>
          <b-button
            slot="finish"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            Confirm
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </b-button>
        </form-wizard>
      </b-overlay>
    </b-modal>
    <!-- 汇率 modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="exchange-rate-modal"
      hide-footer
      title="Exchange Rate"
      ok-title="confirm"
      cancel-variant="outline-secondary"
      size="lg"
      @show="resetRateModal"
      @hidden="resetRateModal"
      @ok="handleRateOk"
    >
      <b-overlay
        :show="isExchangeLoading"
        spinner-variant="primary"
        variant="light"
        opacity="0.7"
        rounded="sm"
      >
        <b-table
          ref="refSettlementTable"
          class="position-relative"
          :items="exchangeRatetableItems"
          :per-page="exchangePerPage"
          :current-page="exchangeCurrentPage"
          responsive
          :fields="exchangeColumns"
          primary-key="uid"
          show-empty
          empty-text="No matching records found"
        />
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ exchangeDataMeta.from }} to {{ exchangeDataMeta.to }} of {{ exchangeDataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="exchangeCurrentPage"
                :total-rows="exchangeTotal"
                :per-page="exchangePerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
    <!-- 费率 modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="exchange-fee-modal"
      title="Processing Fee"
      ok-title="confirm"
      cancel-variant="outline-secondary"
      size="xl"
      hide-footer
      @show="resetFeeModal"
      @hidden="resetFeeModal"
      @ok="handleFeeOk"
    >
      <b-overlay
        :show="isProcessFeeLoading"
        spinner-variant="primary"
        variant="light"
        opacity="0.7"
        rounded="sm"
      >
        <b-table
          ref="refExchangeFeeTable"
          class="position-relative"
          :items="processingFeetableItems"
          :per-page="processingFeePerPage"
          :current-page="processingFeeCurrentPage"
          responsive
          :fields="processingFeeColumns"
          primary-key="uid"
          show-empty
          empty-text="No matching records found"
        />
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ processingFeeDataMeta.from }} to {{ processingFeeDataMeta.to }} of {{ processingFeeDataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="processingFeeCurrentPage"
                :total-rows="processingFeeTotal"
                :per-page="processingFeePerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, url,
} from '@validations'
import {
  BCard, BCardHeader, BCardTitle, BButton, BRow, BCol, BFormGroup, BTable, BPagination, BFormCheckbox, VBModal, BFormInput, BOverlay,
} from 'bootstrap-vue'
import {
  defineComponent, reactive, getCurrentInstance, computed, watch, onMounted, toRefs,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { showToast } from '@/libs/tool'
// 分步骤配置
import store from '@/store'
import profitSharingConfig from './component/profitSharingConfig.vue'
import manualAdjustment from './component/manualAdjust.vue'
import exchangeRateConfiguration from './component/exchangeRateConfiguration.vue'
import descpConfiguration from './component/descpConfiguration.vue'

const useSettlementConfigTable = proxy => {
  const state = reactive({
    isSettlementLoading: false,
    isShowSettlementTable: true,
    refSettlementTable: '',
    tableItems: [],
    perPage: 10,
    currentPage: 1,
    total: 0,
    tableColumns: [
      // { key: 'id', label: 'id' },
      { key: 'formula', label: 'Formula' },
      { key: 'report_url', label: 'File' },
      // { key: 'order_total_amount', label: 'order amount' },
      // { key: 'processing_fee_total_amount', label: 'processing fee amount' },
      { key: 'revenue_share_total_amount', label: 'revenue share amount' },
      { key: 'exchangeRate', label: 'exchange rate' }, // 汇率
      { key: 'processingFee', label: 'processing fee' }, // 费率
      { key: 'status', label: 'Status' },
      { key: 'periods', label: 'periods' },
    ],
  })
  const {
    isShowSettlementTable, isSettlementLoading, refSettlementTable, tableItems, perPage, currentPage, total, tableColumns,
  } = toRefs(state)
  const dataMeta = computed(() => ({
    from: state.perPage * (state.currentPage - 1) + (state.total ? 1 : 0),
    to: state.total - state.currentPage * 10 > 0 ? state.currentPage * 10 : state.total,
    of: state.total,
  }))
  // fetch
  const fetchResellerRevenueList = () => {
    state.isSettlementLoading = true
    store.dispatch('resellerKyc/resellerRevenueList', { reseller_id: proxy.resellerId }).then(res => {
      state.isSettlementLoading = false
      const { code, msg, data } = res.data
      if (code === '10000') {
        state.tableItems = data
        state.total = data.length
      } else {
        showToast(proxy, 'Warning', `fail with ${msg}`)
      }
    }).catch(err => {
      state.isSettlementLoading = false
      showToast(proxy, 'Warning', `fail with ${err}`)
    })
  }
  const changeRevShareStatus = item => {
    state.isSettlementLoading = true
    state.isShowSettlementTable = false
    store.dispatch('resellerKyc/toggleRevenueStatus', { id: item.id, reseller_id: item.reseller_id }).then(res => {
      state.isSettlementLoading = false
      state.isShowSettlementTable = true
      const { code, msg } = res.data
      if (code === '10000') {
        showToast(proxy, 'Success', 'Success')
      } else {
        showToast(proxy, 'Warning', `toggle fail with ${msg}`)
      }
      fetchResellerRevenueList()
    }).catch(err => {
      state.isSettlementLoading = false
      state.isShowSettlementTable = true
      fetchResellerRevenueList()
      showToast(proxy, 'Warning', `toggle fail with ${err}`)
    })
  }
  const uploadFileChange = (event, item) => {
    const file = event.currentTarget.files[0]
    console.log('file=', file)
    store.dispatch('resellerKyc/revenueOverrideReport', { revenueId: item.id, report_file: file }).then(res => {
      state.isSettlementLoading = false
      state.isShowSettlementTable = true
      const { code, msg } = res.data
      if (code === '10000') {
        showToast(proxy, 'Success', 'Success')
      } else {
        showToast(proxy, 'Warning', `upload fail with ${msg}`)
      }
      fetchResellerRevenueList()
    }).catch(err => {
      state.isSettlementLoading = false
      state.isShowSettlementTable = true
      fetchResellerRevenueList()
      showToast(proxy, 'Warning', `upload fail with ${err}`)
    })
  }
  return {
    dataMeta, isShowSettlementTable, isSettlementLoading, refSettlementTable, tableItems, perPage, currentPage, total, tableColumns, fetchResellerRevenueList, changeRevShareStatus, uploadFileChange,
  }
}
const useSettlementCreateModal = (proxy, fetchResellerRevenueList) => {
  const state = reactive({
    showWizard: false,
    settlementTitle: 'Create',
    settlementOkTitle: 'Create',
    wizardIndex: 0,
    revenueId: '',
    isCreateLoading: false,
  })
  const {
    showWizard, settlementTitle, settlementOkTitle, wizardIndex, revenueId, isCreateLoading,
  } = toRefs(state)
  const createSettlementConfig = () => {
    state.settlementTitle = 'Create'
    state.settlementOkTitle = 'Create'
    state.showWizard = true
    state.wizardIndex = 0
    proxy.$refs['settlement-create-modal'].show()
  }
  const editSettlementConfig = item => {
    console.log(item.id)
    state.settlementTitle = 'Edit'
    state.settlementOkTitle = 'Confirm'
    state.showWizard = true
    state.wizardIndex = 0
    proxy.$refs['settlement-create-modal'].show()
  }
  const hiddenResetModal = () => {
    state.showWizard = false
    state.wizardIndex = 0
    state.revenueId = ''
  }
  // step 1
  const validationProfitSharing = () => new Promise(resolve => {
    // console.log('validationProfitSharing')
    // resolve(true)
    state.isCreateLoading = true
    proxy.$refs['profit-sharing'].saveProfitSharing(data => {
      state.isCreateLoading = false
      if (data) {
        state.revenueId = data
        resolve(true)
      } else {
        resolve(false)
      }
    })
  })
  // step 2
  const validationManualAdjustment = () => new Promise(resolve => {
    // resolve(true)
    state.isCreateLoading = true
    proxy.$refs['manual-adjust'].saveManualAdjust(data => {
      state.isCreateLoading = false
      if (data) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  })
  // step 3
  const validationExchangeRate = () => new Promise(resolve => {
    // resolve(true)
    state.isCreateLoading = true
    proxy.$refs['exchange-rate'].saveExchangeRate(data => {
      state.isCreateLoading = false
      if (data) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  })
  // step 4
  const validationDescpConfiguration = () => new Promise(resolve => {
    // resolve(true)
    state.isCreateLoading = true
    proxy.$refs['descp-config'].saveDescp(data => {
      state.isCreateLoading = false
      if (data) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  })
  const wizardSubmitted = () => {
    console.log('Confirm')
    proxy.$nextTick(() => {
      proxy.$refs['settlement-create-modal'].hide()
      fetchResellerRevenueList()
      state.showWizard = false
      state.revenueId = ''
    })
  }
  // const wizardChange = (prevIndex, nextIndex) => {
  //   console.log(`wizardChange-${prevIndex}-${nextIndex}`)
  // }
  const wizardChange = () => {}
  const wizardUpdateIndex = nextIndex => {
    // console.log(`wizardUpdateIndex-nextIndex-${nextIndex}`)
    switch (nextIndex) {
      case 0:
        state.wizardIndex = 0
        break
      case 1:
        state.wizardIndex = 1
        break
      case 2:
        state.wizardIndex = 2
        break
      case 3:
        state.wizardIndex = 3
        break
      default:
        break
    }
  }
  return {
    createSettlementConfig, editSettlementConfig, hiddenResetModal, validationProfitSharing, validationManualAdjustment, validationExchangeRate, validationDescpConfiguration, wizardSubmitted, wizardChange, wizardUpdateIndex, showWizard, settlementTitle, settlementOkTitle, wizardIndex, revenueId, isCreateLoading,
  }
}
const useRateModal = proxy => {
  const state = reactive({
    exchangeColumns: [
      { key: 'id', label: 'id' },
      { key: 'periods', label: 'periods' },
      { key: 'initial_currency', label: 'Initial Currency' },
      { key: 'target_currency', label: 'Target Currency' },
      { key: 'rate', label: 'rate' },
    ],
    exchangeRatetableItems: [],
    exchangePerPage: 10,
    exchangeCurrentPage: 1,
    exchangeTotal: 0,
    isExchangeLoading: false,
  })
  const {
    exchangeColumns, exchangeRatetableItems, exchangePerPage, exchangeCurrentPage, exchangeTotal, isExchangeLoading,
  } = toRefs(state)
  // check 汇率
  const checkRate = id => {
    state.isExchangeLoading = true
    store.dispatch('resellerKyc/resellerCheckExchangeRates', { revenueId: id }).then(res => {
      state.isExchangeLoading = false
      const { code, msg, data } = res.data
      if (code === '10000') {
        state.exchangeRatetableItems = data
        state.exchangeTotal = data.length
      } else {
        showToast(proxy, 'Warning', `fail with ${msg}`)
      }
    }).catch(err => {
      state.isExchangeLoading = false
      showToast(proxy, 'Warning', `fail with ${err}`)
    })
    proxy.$refs['exchange-rate-modal'].show()
  }
  const resetRateModal = () => {
  }
  const handleRateOk = bvModalEvt => {
    bvModalEvt.preventDefault()
    proxy.$refs['exchange-rate-modal'].hide()
  }
  const exchangeDataMeta = computed(() => ({
    from: state.exchangePerPage * (state.exchangeCurrentPage - 1) + (state.exchangeTotal ? 1 : 0),
    to: state.exchangeTotal - state.exchangeCurrentPage * 10 > 0 ? state.exchangeCurrentPage * 10 : state.exchangeTotal,
    of: state.exchangeTotal,
  }))
  return {
    checkRate, resetRateModal, handleRateOk, exchangeDataMeta, exchangeColumns, exchangeRatetableItems, exchangePerPage, exchangeCurrentPage, exchangeTotal, isExchangeLoading,
  }
}
const useFeeModal = proxy => {
  const state = reactive({
    processingFeeColumns: [
      { key: 'id', label: 'id' },
      { key: 'periods', label: 'periods' },
      { key: 'app_id', label: 'app id' },
      { key: 'app_name', label: 'app name' },
      { key: 'currency', label: 'currency' },
      { key: 'merchant_no', label: 'merchant no' },
      { key: 'modified_amount', label: 'modified amount' },
      { key: 'order_total_amount', label: 'order total amount' },
      { key: 'processing_fee_total_amount', label: 'processing fee total amount' },
      { key: 'revenue_share_total_amount', label: 'revenue share total amount' },
      { key: 'status', label: 'status' },
    ],
    processingFeetableItems: [],
    processingFeePerPage: 10,
    processingFeeCurrentPage: 1,
    processingFeeTotal: 0,
    isProcessFeeLoading: false,
  })
  const {
    processingFeeColumns, processingFeetableItems, processingFeePerPage, processingFeeCurrentPage, processingFeeTotal, isProcessFeeLoading,
  } = toRefs(state)
  // check 费率
  const checkFee = id => {
    state.isProcessFeeLoading = true
    store.dispatch('resellerKyc/resellerCheckFeeRates', { revenueId: id }).then(res => {
      state.isProcessFeeLoading = false
      const { code, msg, data } = res.data
      if (code === '10000') {
        state.processingFeetableItems = data
        state.processingFeeTotal = data.length
      } else {
        showToast(proxy, 'Warning', `fail with ${msg}`)
      }
    }).catch(err => {
      state.isProcessFeeLoading = false
      showToast(proxy, 'Warning', `fail with ${err}`)
    })
    proxy.$refs['exchange-fee-modal'].show()
  }
  const resetFeeModal = () => {

  }
  const handleFeeOk = bvModalEvt => {
    bvModalEvt.preventDefault()
    proxy.$refs['exchange-fee-modal'].hide()
  }
  const processingFeeDataMeta = computed(() => ({
    from: state.processingFeePerPage * (state.processingFeeCurrentPage - 1) + (state.processingFeeTotal ? 1 : 0),
    to: state.processingFeeTotal - state.processingFeeCurrentPage * 10 > 0 ? state.processingFeeCurrentPage * 10 : state.processingFeeTotal,
    of: state.processingFeeTotal,
  }))
  return {
    checkFee, resetFeeModal, handleFeeOk, processingFeeDataMeta, processingFeeColumns, processingFeetableItems, processingFeePerPage, processingFeeCurrentPage, processingFeeTotal, isProcessFeeLoading,
  }
}
export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    // 代理商分润列表
    const {
      dataMeta, isShowSettlementTable, isSettlementLoading, refSettlementTable, tableItems, perPage, currentPage, total, tableColumns, fetchResellerRevenueList, changeRevShareStatus, uploadFileChange,
    } = useSettlementConfigTable(proxy)
    const {
      createSettlementConfig, editSettlementConfig, hiddenResetModal, validationProfitSharing, validationManualAdjustment, validationExchangeRate, validationDescpConfiguration, wizardSubmitted, wizardChange, wizardUpdateIndex, showWizard, settlementTitle, settlementOkTitle, wizardIndex, revenueId, isCreateLoading,
    } = useSettlementCreateModal(proxy, fetchResellerRevenueList)
    const {
      checkRate, resetRateModal, handleRateOk, exchangeDataMeta, exchangeColumns, exchangeRatetableItems, exchangePerPage, exchangeCurrentPage, exchangeTotal, isExchangeLoading,
    } = useRateModal(proxy)
    const {
      checkFee, resetFeeModal, handleFeeOk, processingFeeDataMeta, processingFeeColumns, processingFeetableItems, processingFeePerPage, processingFeeCurrentPage, processingFeeTotal, isProcessFeeLoading,
    } = useFeeModal(proxy)

    watch(() => proxy.tabIdx, value => {
      if (value === '3') {
        proxy.$router.push({ name: 'reseller-info-detail', query: { reseller_id: proxy.resellerId, from: 'settlement-ment' } }, () => {}, () => {})
        fetchResellerRevenueList()
      }
    })
    onMounted(() => {
      if (proxy.tabIdx === '3') {
        fetchResellerRevenueList()
      }
    })
    return {
      createSettlementConfig,
      editSettlementConfig,
      isSettlementLoading,
      isShowSettlementTable,
      refSettlementTable,
      tableItems,
      perPage,
      currentPage,
      total,
      tableColumns,
      dataMeta,
      changeRevShareStatus,
      uploadFileChange,
      // modal
      showWizard,
      settlementTitle,
      settlementOkTitle,
      wizardIndex,
      revenueId,
      isCreateLoading,

      hiddenResetModal,
      validationProfitSharing,
      validationManualAdjustment,
      validationExchangeRate,
      validationDescpConfiguration,
      wizardSubmitted,
      wizardChange,
      wizardUpdateIndex,
      // check 汇率
      checkRate,
      resetRateModal,
      handleRateOk,
      exchangeDataMeta,
      exchangeColumns,
      exchangeRatetableItems,
      exchangePerPage,
      exchangeCurrentPage,
      exchangeTotal,
      isExchangeLoading,
      // check 费率
      checkFee,
      resetFeeModal,
      handleFeeOk,
      processingFeeDataMeta,
      processingFeeColumns,
      processingFeetableItems,
      processingFeePerPage,
      processingFeeCurrentPage,
      processingFeeTotal,
      isProcessFeeLoading,
      // validate 验证规则
      required,
      email,
      url,
    }
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BPagination,
    BFormCheckbox,
    BFormInput,
    BOverlay,

    ValidationProvider,
    ValidationObserver,

    FormWizard,
    TabContent,
    // 分步骤
    profitSharingConfig,
    manualAdjustment,
    exchangeRateConfiguration,
    descpConfiguration,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    tabIdx: {
      type: String,
      default: '',
    },
    resellerId: {
      type: String,
      default: '',
    },
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
.settlement-template-box {
  display: flex;
  flex-direction: row;
}
.upload-button-box {
  // width: 100px;
  // height: 38px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  .upload-button {
    position: relative;
    cursor: pointer;
  }
  .upload-file-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 38px;
    opacity: 0;
    width: 100px;
    cursor: pointer;
  }
}
</style>
