<template>
  <div>
    <b-table
      v-if="isShowTable"
      ref="refSettlementTable"
      class="position-relative"
      :items="processingFeeItems"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      :fields="tableColumns"
      primary-key="uid"
      show-empty
      empty-text="No matching records found"
    >
      <!-- Column: app_name -->
      <template #cell(app_name)="data">
        <div class="text-nowrap">
          {{ data.item.app_name }}({{ data.item.app_id }})
        </div>
      </template>
      <!-- Column: Action -->
      <template #cell(action)="data">
        <div class="text-nowrap">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="editManual(data.item)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">Edit</span>
          </b-button>
        </div>
      </template>
    </b-table>
    <!-- <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div> -->
    <!-- modal -->
    <b-modal
      ref="manual-modal"
      title="Edit"
      ok-title="confirm"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="manualAdjustRules">
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Please enter the modified amount"
                label-for="modifiedAmount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required"
                >
                  <b-form-input
                    id="modifiedAmount"
                    v-model="modifiedAmount"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Please enter the modified amount"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Descp"
                label-for="descp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Descp"
                  rules="required"
                >
                  <b-form-input
                    id="descp"
                    v-model="descp"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Descp"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, url,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import {
  defineComponent, ref, reactive, computed, getCurrentInstance, onMounted, watch,
} from '@vue/composition-api'
import {
  BRow, BCol, BFormGroup, BFormInput, BTable, BPagination, BFormCheckbox, BButton, VBModal,
} from 'bootstrap-vue'
import store from '@/store'
import { showToast } from '@/libs/tool'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    // table buyinRate
    const refSettlementTable = ref('')
    const processingFeeItems = ref([])
    const perPage = ref(99999)
    const currentPage = ref(1)
    const total = ref(0)
    const tableColumns = reactive([
      { key: 'merchant_no', label: 'Merchant' },
      { key: 'app_name', label: 'App' },
      { key: 'currency', label: 'Currency' },
      { key: 'processing_fee_total_amount', label: 'Processing Fee Total Amount' },
      { key: 'revenue_share_total_amount', label: 'Revenue Share Total Amount' },
      { key: 'action', label: 'Action' },
      { key: 'modified_amount', label: 'Modified Amount' },
      { key: 'descp', label: 'Descp' },
    ])
    const appIdValue = ref('')
    const isShowTable = ref(true)

    const dataMeta = computed(() => ({
      from: perPage.value * (currentPage.value - 1) + (total.value ? 1 : 0),
      to: total.value - currentPage.value * 10 > 0 ? currentPage.value * 10 : total.value,
      of: total.value,
    }))
    const modifiedAmount = ref('')
    const descp = ref('')
    const editManual = item => {
      appIdValue.value = item.id
      modifiedAmount.value = item.modified_amount ? item.modified_amount : ''
      descp.value = item.descp ? item.descp : ''
      proxy.$refs['manual-modal'].show()
    }
    const resetModal = () => {}
    const handleSubmit = () => {
      proxy.$refs.manualAdjustRules.validate().then(success => {
        if (success) {
          isShowTable.value = false
          if (processingFeeItems.value.length > 0) {
            processingFeeItems.value.forEach(item => {
              if (item.id === appIdValue.value) {
                // eslint-disable-next-line no-param-reassign
                item.modified_amount = modifiedAmount.value
                // eslint-disable-next-line no-param-reassign
                item.descp = descp.value
              }
            })
          }
          proxy.$nextTick(() => {
            proxy.$refs['manual-modal'].hide()
            isShowTable.value = true
          })
        }
      })
    }
    const handleOk = bvModalEvt => {
      bvModalEvt.preventDefault()
      handleSubmit()
    }
    const saveManualAdjust = callback => {
      store.dispatch('resellerKyc/resellerRevenueConfirmStep2', { reseller_id: proxy.resellerId, tableItems: processingFeeItems.value }).then(res => {
        const { code, msg, data } = res.data
        if (code === '10000') {
          callback(data)
        } else {
          callback(false)
          showToast(proxy, 'Warning', msg)
        }
      }).catch(() => {
        callback(false)
      })
    }
    const fetchProcessingFeeRates = revenueId => {
      store.dispatch('resellerKyc/resellerCheckFeeRates', { revenueId }).then(res => {
        const { code, msg, data } = res.data
        if (code === '10000') {
          processingFeeItems.value = data
          total.value = data.length
        } else {
          showToast(proxy, 'Warning', `fail with ${msg}`)
        }
      })
    }
    onMounted(() => {
      if (proxy.wizardIndex === 1) {
        // console.log('manu')
      }
    })
    watch(() => proxy.wizardIndex, value => {
      if (value === 1) {
        if (proxy.revenueId) {
          console.log(value, proxy.revenueId)
          fetchProcessingFeeRates(proxy.revenueId)
        } else {
          console.error('fail to get param revenid')
        }
      }
    })
    return {
      refSettlementTable,
      processingFeeItems,
      perPage,
      currentPage,
      total,
      tableColumns,
      isShowTable,
      dataMeta,
      editManual,

      modifiedAmount,
      descp,
      resetModal,
      handleSubmit,
      handleOk,
      // validate 验证规则
      required,
      email,
      url,

      saveManualAdjust,
    }
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTable,
    BPagination,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    resellerId: {
      type: String,
      default: '',
    },
    wizardIndex: {
      type: Number,
      default: 0,
    },
    revenueId: {
      type: String,
      default: '',
    },
  },
})
</script>
<style scoped>

</style>
