<template>
  <ul class="tab-wrap">
    <li
      v-for="(item,index) in tabItems"
      :key="index"
      :class="{selected: index == active}"
      @click="() => $emit('update:active', index.toString())"
    >
      {{ item }}
    </li>
  </ul>
</template>
<script>

export default {
  props: {
    tabItems: {
      type: Array,
      default: () => [],
      required: true,
    },
    active: {
      type: String,
      default: '0',
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
ul,li{
  list-style: none;
}
.tab-wrap{
  width: 100%;
  height: 50px;
  padding-left: 0;
  border-bottom: 1px solid #7367f0;
  display: flex;
  li{
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding: 0 20px;
    text-align: center;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    &.selected{
      border-top: 1px solid #7367f0;
      border-left: 1px solid #7367f0;
      border-right: 1px solid #7367f0;
      border-bottom: 1px solid#fff;
      font-weight: 500;
      color: #7367f0;
    }
  }
}
</style>
