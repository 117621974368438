<template>
  <div class="mb-2">
    <validation-observer ref="descpRule">
      <validation-provider
        #default="{ errors }"
        name="Descp"
        rules="required"
      >
        <b-form-textarea
          v-model="descp"
          rows="3"
          :state="errors.length > 0 ? false:null"
          placeholder="Descp"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, url,
} from '@validations'
import {
  defineComponent, ref, getCurrentInstance, onMounted, watch,
} from '@vue/composition-api'
import { BFormTextarea } from 'bootstrap-vue'
import store from '@/store'
import { showToast } from '@/libs/tool'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const descp = ref('')
    const saveDescp = callback => {
      proxy.$refs.descpRule.validate().then(success => {
        if (success) {
          store.dispatch('resellerKyc/resellerRevenueConfirm', { reseller_id: proxy.resellerId, descp: descp.value, revenueId: proxy.revenueId }).then(res => {
            const { code, msg, data } = res.data
            if (code === '10000') {
              callback(data)
            } else {
              callback(false)
              showToast(proxy, 'Warning', msg)
            }
          }).catch(() => {
            callback(false)
          })
        }
      })
    }
    onMounted(() => {
      if (proxy.wizardIndex === 3) {
        console.log('descp')
      }
    })
    watch(() => proxy.wizardIndex, value => {
      if (value === 3) {
        console.log(value)
      }
    })
    return {
      descp,
      saveDescp,
      // validate 验证规则
      required,
      email,
      url,
    }
  },
  components: {
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    resellerId: {
      type: String,
      default: '',
    },
    wizardIndex: {
      type: Number,
      default: 0,
    },
    revenueId: {
      type: String,
      default: '',
    },
  },
})
</script>
<style scoped>

</style>
