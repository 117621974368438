<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mb-1"
      @click="addNew"
    >
      Add New
    </b-button>
    <b-table
      v-if="isShowTable"
      ref="refSettlementTable"
      class="position-relative"
      :items="tableItems"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      :fields="tableColumns"
      primary-key="uid"
      show-empty
      empty-text="No matching records found"
    >
      <!-- Column: Action -->
      <template #cell(action)="data">
        <div class="text-nowrap">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="editExchangeRate(data.index)"
          >
            <feather-icon icon="EditIcon" class="mr-50" />
            <span class="align-middle">Edit</span>
          </b-button>
        </div>
      </template>
    </b-table>
    <!-- <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div> -->
    <!-- modal -->
    <b-modal
      ref="exchange-rate-modal"
      :title="isEdit ? 'Edit' : 'Add'"
      ok-title="confirm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <validation-observer ref="exchangeRateRules">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                label="Initial Currency"
                label-for="initial_currency"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Initial Currency"
                  rules="required"
                >
                  <v-select
                    id="initial_currency"
                    v-model="initialCurrency"
                    :state="errors.length > 0 ? false : null"
                    :options="initialCurrencyOptions"
                    :clearable="false"
                    class="w-100"
                    placeholder="Initial Currency"
                    :reduce="(val) => val.value"
                    :disabled="isEdit"
                    @input="changeInitialCurrency"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group label="Target Currency" label-for="target_currency">
                <validation-provider
                  #default="{ errors }"
                  name="Target Currency"
                  rules="required"
                >
                  <v-select
                    id="target_currency"
                    v-model="targetCurrency"
                    :state="errors.length > 0 ? false : null"
                    :options="currencyOptions"
                    :clearable="false"
                    class="w-100"
                    placeholder="Target Currency"
                    :reduce="(val) => val.value"
                    @input="changeTargetCurrency"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group label="Rate" label-for="rate">
                <validation-provider
                  #default="{ errors }"
                  name="Rate"
                  rules="required"
                >
                  <b-form-input
                    id="rate"
                    v-model="rate"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Rate"
                    autocomplete="off"
                    :disabled="rateStatus"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import Ripple from 'vue-ripple-directive'
import {
  defineComponent,
  ref,
  reactive,
  computed,
  getCurrentInstance,
  onMounted,
  watch,
} from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTable,
  BPagination,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { showToast } from '@/libs/tool'
import vSelect from 'vue-select'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    // table buyinRate
    const refSettlementTable = ref('')
    const tableItems = ref([])
    const perPage = ref(99999)
    const currentPage = ref(1)
    const total = ref(0)
    const tableColumns = reactive([
      { key: 'initial_currency', label: 'Initial Currency' },
      { key: 'target_currency', label: 'Target Currency' },
      { key: 'rate', label: 'rate' },
      { key: 'action', label: 'action' },
    ])
    const dataMeta = computed(() => ({
      from: perPage.value * (currentPage.value - 1) + (total.value ? 1 : 0),
      to:
        total.value - currentPage.value * 10 > 0
          ? currentPage.value * 10
          : total.value,
      of: total.value,
    }))
    const saveExchangeRate = callback => {
      const isValueEmpty = tableItems.value.some(
        value => value.target_currency === undefined || value.rate === undefined,
      )
      if (isValueEmpty) {
        callback(false)
        showToast(proxy, 'Warning', 'Error！Target Currency CANNOT be Null!')
        return
      }
      console.log(isValueEmpty)
      store
        .dispatch('resellerKyc/resellerRevenueConfirmStep3', {
          reseller_id: proxy.resellerId,
          tableItems: tableItems.value,
        })
        .then(res => {
          const { code, msg, data } = res.data
          if (code === '10000') {
            callback(data)
          } else {
            callback(false)
            showToast(proxy, 'Warning', msg)
          }
        })
        .catch(() => {
          callback(false)
        })
    }
    const fetchExchangeRates = revenueId => {
      store
        .dispatch('resellerKyc/resellerCheckExchangeRates', { revenueId })
        .then(res => {
          const { code, msg, data } = res.data
          if (code === '10000') {
            tableItems.value = data
            total.value = data.length
          } else {
            showToast(proxy, 'Warning', `fail with ${msg}`)
          }
        })
    }
    // modal
    const isEdit = ref(false)
    const editIndex = ref(null)
    const isShowTable = ref(true)
    const initialCurrency = ref('')
    const targetCurrency = ref('')
    const rate = ref('')
    const rateStatus = ref(false)
    const currencyOptions = ref([])
    const initialCurrencyOptions = ref([])
    const addNew = () => {
      isEdit.value = false
      initialCurrency.value = ''
      targetCurrency.value = ''
      rate.value = ''
      // initialCurrencyOptions 过滤已经有的initial数据
      initialCurrencyOptions.value = initialCurrencyOptions.value.filter(
        currency => tableItems.value.every(
          item => item.initial_currency !== currency.value,
        ),
      )
      //
      proxy.$refs['exchange-rate-modal'].show()
    }
    const editExchangeRate = index => {
      isEdit.value = true
      editIndex.value = index
      initialCurrency.value = tableItems.value[index].initial_currency
      targetCurrency.value = tableItems.value[index].target_currency
      rate.value = tableItems.value[index].rate
      proxy.$refs['exchange-rate-modal'].show()
    }
    const resetModal = () => {}
    const handleSubmit = () => {
      proxy.$refs.exchangeRateRules.validate().then(success => {
        if (success) {
          isShowTable.value = false
          if (isEdit.value) {
            tableItems.value[editIndex.value].initial_currency = initialCurrency.value
            tableItems.value[editIndex.value].target_currency = targetCurrency.value
            tableItems.value[editIndex.value].rate = rate.value
          } else {
            tableItems.value.push({
              revenue_id: proxy.revenueId,
              initial_currency: initialCurrency.value,
              target_currency: targetCurrency.value,
              rate: rate.value,
            })
          }

          proxy.$nextTick(() => {
            proxy.$refs['exchange-rate-modal'].hide()
            isShowTable.value = true
          })
        }
      })
    }
    const handleOk = bvModalEvt => {
      bvModalEvt.preventDefault()
      handleSubmit()
    }
    // 弹框下拉数据
    // Initial Currency
    // const fetchInitialCurrencyOptions = () => {
    //   store
    //     .dispatch('resellerKyc/fetchInitialCurrencyOptions', {
    //       revenueId: proxy.revenueId,
    //     })
    //     .then(res => {
    //       const { code, msg, data } = res.data
    //       if (code === '10000') {
    //         initialCurrencyOptions.value = data.map(item => ({
    //           label: item,
    //           value: item,
    //         }))
    //       } else {
    //         console.log(msg)
    //       }
    //     })
    // }
    // Target Currency
    const fetchResellerBuyinOptions = () => {
      store
        .dispatch('resellerKyc/fetchResellerBuyinOptions', {})
        .then(res => {
          const { code, msg, data } = res.data
          if (code === '10000') {
            const { currencies } = data
            currencyOptions.value = currencies.map(item => ({
              label: item.code,
              value: item.code,
            }))
            initialCurrencyOptions.value = currencies.map(item => ({
              label: item.code,
              value: item.code,
            }))
          } else {
            console.log(msg)
          }
        })
    }

    onMounted(() => {
      if (proxy.wizardIndex === 2) {
        console.log('exchange')
      }
    })
    watch(
      () => proxy.wizardIndex,
      value => {
        if (value === 2) {
          if (proxy.revenueId) {
            fetchExchangeRates(proxy.revenueId)
            fetchResellerBuyinOptions()
            // fetchInitialCurrencyOptions()
          } else {
            console.error('fail to get param revenid')
          }
        }
      },
    )
    const changeInitialCurrency = item => {
      if (targetCurrency.value === item) {
        rate.value = 1
        rateStatus.value = true
      } else {
        rate.value = ''
        rateStatus.value = false
      }
    }
    const changeTargetCurrency = item => {
      if (initialCurrency.value === item) {
        rate.value = 1
        rateStatus.value = true
      } else {
        rate.value = ''
        rateStatus.value = false
      }
    }

    return {
      refSettlementTable,
      tableItems,
      perPage,
      currentPage,
      total,
      tableColumns,
      dataMeta,

      saveExchangeRate,
      // modal
      addNew,
      editExchangeRate,
      resetModal,
      handleSubmit,
      handleOk,
      isShowTable,
      initialCurrency,
      targetCurrency,
      rate,
      rateStatus,
      currencyOptions,
      initialCurrencyOptions,
      // validate 验证规则
      required,
      email,
      url,
      isEdit,

      changeInitialCurrency,
      changeTargetCurrency,
    }
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTable,
    BPagination,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    resellerId: {
      type: String,
      default: '',
    },
    wizardIndex: {
      type: Number,
      default: 0,
    },
    revenueId: {
      type: String,
      default: '',
    },
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
