<template>
  <b-overlay
    :show="isKYCInfoLoading"
    spinner-variant="primary"
    variant="light"
    opacity="0.7"
    rounded="sm"
  >
    <div class="reseller-kyc">
      <b-card title="Reseller KYC Info">
        <b-card-text><span class="information-type">Status：</span>{{ infoStatus }}</b-card-text>
        <b-card-text><span class="information-type">Type：</span>{{ infoType }}</b-card-text>
        <b-card-text><span class="information-type">Country：</span>{{ infoCountry }}</b-card-text>
      </b-card>
      <b-card
        title="Regular KYC"
        class="mt-2"
      >
        <div v-if="infoType==='Individual'">
          <b-row>
            <b-col
              cols="12"
              md="3"
              style="line-height: 38px"
            >
              Proof of ID
            </b-col>
            <b-col
              md="12"
              xl="7"
            >
              <b-form-group
                label-cols="8"
                label-cols-lg="3"
                label="ID Number"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="idNumber"
                  placeholder="ID Number"
                  :disabled="isKycDisabled"
                />
              </b-form-group>
              <b-form-group
                label-cols="8"
                label-cols-lg="3"
                label="ID/Passport Cover page"
                label-for="input-default"
              >
                <div class="files-list">
                  <div class="file">
                    {{ PositivePassportUrl.split("/").slice(-1)[0] }}
                  </div>
                  <a :href="PositivePassportUrl" target="_blank" rel="front Passport">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                    >
                      View
                    </b-button>
                  </a>
                </div>
              </b-form-group>

              <b-form-group
                class="mt-1"
                label-cols="8"
                label-cols-lg="3"
                label="ID/Passport Cover page"
                label-for="input-default"
              >
                <div class="files-list">
                  <div class="file">
                    {{ BackPassportUrl.split("/").slice(-1)[0] }}
                  </div>
                  <a :href="BackPassportUrl" target="_blank" rel="back Passport">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                    >
                      View
                    </b-button>
                  </a>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              cols="12"
              md="3"
              style="line-height: 38px"
            >
              Proof of Address
            </b-col>
            <b-col
              md="12"
              xl="7"
            >
              <div class="files-list">
                <div class="file">
                  {{ ProofOfAddressUrl.split("/").slice(-1)[0] }}
                </div>
                <a :href="ProofOfAddressUrl" target="_blank" rel="Proof of Address">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    View
                  </b-button>
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="infoType==='Entity' && infoCountry==='Hong Kong'">
          <b-row class="mt-2">
            <b-col
              md="12"
              xl="3"
              style="line-height: 38px"
            >
              Registration NO
            </b-col>
            <b-col
              md="12"
              xl="7"
            >
              <b-form-input
                id="registration-no"
                v-model="registrationNO"
                placeholder="Registration NO"
                :disabled="isKycDisabled"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              md="12"
              xl="3"
              style="line-height: 38px"
            >
              Business Registration
            </b-col>
            <b-col
              md="12"
              xl="7"
            >
              <div class="files-list">
                <div class="file">
                  {{ BusinessRegistrationUrl.split("/").slice(-1)[0] }}
                </div>
                <a :href="BusinessRegistrationUrl" target="_blank" rel="front Passport">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    View
                  </b-button>
                </a>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              md="12"
              xl="3"
              style="line-height: 38px"
            >
              Annual Return(Form NAR1)
            </b-col>
            <b-col
              md="12"
              xl="7"
            >
              <div class="files-list">
                <div class="file">
                  {{ AnnualReturnUrl.split("/").slice(-1)[0] }}
                </div>
                <a :href="AnnualReturnUrl" target="_blank" rel="front Passport">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    View
                  </b-button>
                </a>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              md="12"
              xl="3"
              style="line-height: 38px"
            >
              Certificate of Incorporation
            </b-col>
            <b-col
              md="12"
              xl="7"
            >
              <div class="files-list">
                <div class="file">
                  {{ CertificateUrl.split("/").slice(-1)[0] }}
                </div>
                <a :href="CertificateUrl" target="_blank" rel="front Passport">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    View
                  </b-button>
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="infoType==='Entity' && infoCountry!=='Hong Kong'">
          <b-row class="mt-2">
            <b-col
              md="12"
              xl="3"
              style="line-height: 38px"
            >
              CNPJ/Registration NO
            </b-col>
            <b-col
              md="12"
              xl="7"
            >
              <b-form-input
                id="registration-no"
                v-model="registrationNO"
                placeholder="CNPJ/Registration NO"
                :disabled="isKycDisabled"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              md="12"
              xl="3"
              style="line-height: 38px"
            >
              Certificate of Incorporation
            </b-col>
            <b-col
              md="12"
              xl="7"
            >
              <div class="files-list">
                <div class="file">
                  {{ CertificateUrl.split("/").slice(-1)[0] }}
                </div>
                <a :href="CertificateUrl" target="_blank" rel="front Passport">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    View
                  </b-button>
                </a>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              md="12"
              xl="3"
              style="line-height: 38px"
            >
              Memorandum and Articles of Association
            </b-col>
            <b-col
              md="12"
              xl="7"
            >
              <div class="files-list">
                <div class="file">
                  {{ MemorandumUrl.split("/").slice(-1)[0] }}
                </div>
                <a :href="MemorandumUrl" target="_blank" rel="front Passport">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    View
                  </b-button>
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card
        title="DD Form (Due Dilligence Form)"
        class="mt-2"
      >
        <b-row>
          <b-col
            md="12"
            xl="2"
          >
            <div class="preview-docx">
              <a :href="DDFormUrl" target="_blank" rel="DD FormUrl">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Download
                </b-button>
              </a>
              <span class="ml-1">Preview</span>
            </div>
          </b-col>
          <b-col
            md="12"
            xl="10"
          >
            <div id="container" />
          </b-col>
        </b-row>
      </b-card>
      <fieldset :disabled="isKycDisabled">
        <b-card
          title="Contact Information"
          class="mt-2"
        >
          <b-row>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="Contact Name"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="contactName"
                  placeholder="Contact Name"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="Phone Number"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="contactPhone"
                  placeholder="Phone Number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="Email Address"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="contactEmail"
                  placeholder="Email Address"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="6"
            />
          </b-row>
        </b-card>
        <b-card
          title="Bank Information"
          class="mt-2"
        >
          <b-row>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="Bank Name"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="bankName"
                  placeholder="Bank Name"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="Account Name"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="accountName"
                  placeholder="Account Name"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="Account Number"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="accountNumber"
                  placeholder="Account Number"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="Bank Address"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="bankAddress"
                  placeholder="Bank Address"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="Swift"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="swift"
                  placeholder="Swift"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="IBAN(Mandatory for European Countries)"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="iban"
                  placeholder="IBAN(Mandatory for European Countries)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="BSB Number(Mandatory for Australia)"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="bsbNumber"
                  placeholder="BSB Number(Mandatory for Australia)"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              xl="6"
            >
              <b-form-group
                label="ABA(Routing Number, Mandatory for U.S.)"
                label-for="input-default"
              >
                <b-form-input
                  id="input-default"
                  v-model="aba"
                  placeholder="ABA(Routing Number, Mandatory for U.S.)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </fieldset>
      <b-card>
        <div class="mt-1 text-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="approve"
          >
            Approve
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="ml-2"
            @click="reject"
          >
            Reject
          </b-button>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>
<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { renderAsync } from 'docx-preview'
import {
  BCard, BRow, BCol, BCardText, BButton, BFormGroup, BFormInput, BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import { showToast } from '@/libs/tool'

const useKycInfo = proxy => {
  const state = reactive({
    isKYCInfoLoading: false,
    isKycDisabled: true,
    kycId: 0,
    infoCountry: '', // Hong Kong
    infoStatus: '',
    infoType: '', // Individual Entity

    idNumber: '',
    registrationNO: '',
    PositivePassportUrl: '',
    BackPassportUrl: '',
    ProofOfAddressUrl: '',
    DDFormUrl: '',
    CertificateUrl: '',
    MemorandumUrl: '',
    BusinessRegistrationUrl: '',
    AnnualReturnUrl: '',

    contactName: '',
    contactPhone: '',
    contactEmail: '',
    bankName: '',
    accountName: '',
    accountNumber: '',
    bankAddress: '',
    swift: '',
    iban: '',
    bsbNumber: '',
    aba: '',
  })
  const {
    isKYCInfoLoading, isKycDisabled, kycId, infoCountry, infoStatus, infoType,
    idNumber, registrationNO, PositivePassportUrl, BackPassportUrl, ProofOfAddressUrl, DDFormUrl, CertificateUrl, MemorandumUrl, BusinessRegistrationUrl, AnnualReturnUrl, contactName, contactPhone, contactEmail, bankName, accountName, accountNumber, bankAddress, swift, iban, bsbNumber, aba,
  } = toRefs(state)
  const changeUrlToStream = url => {
    if (!url) { return }
    store.dispatch('resellerKyc/resellerUrlToStream', { url }).then(res => {
      const { data } = res
      renderAsync(data, document.getElementById('container'), null, {
        className: 'docx', // 默认和文档样式类的类名/前缀
        inWrapper: true, // 启用围绕文档内容渲染包装器
        ignoreWidth: false, // 禁止页面渲染宽度
        ignoreHeight: false, // 禁止页面渲染高度
        ignoreFonts: false, // 禁止字体渲染
        breakPages: true, // 在分页符上启用分页
        ignoreLastRenderedPageBreak: true, // 禁用lastRenderedPageBreak元素的分页
        experimental: false, // 启用实验性功能（制表符停止计算）
        trimXmlDeclaration: true, // 如果为真，xml声明将在解析之前从xml文档中删除
        debug: false, // 启用额外的日志记录
      })
    })
  }
  const kycInfoInit = () => {
    state.isKYCInfoLoading = true
    store.dispatch('resellerKyc/resellerKycDetail', { reseller_id: proxy.resellerId }).then(res => {
      state.isKYCInfoLoading = false
      const { code, msg, data } = res.data
      if (code === '10000') {
        const {
          country, status, type, id,
        } = data
        state.kycId = id
        state.infoCountry = country // Hong Kong
        state.infoStatus = status
        state.infoType = type // Entity

        const {
          // eslint-disable-next-line camelcase
          id_number, registration_no, contract_name, phone_number, email_address, reseller_kyc_file, reseller_bank_info,
        } = data
        // eslint-disable-next-line camelcase
        state.idNumber = id_number
        // eslint-disable-next-line camelcase
        state.registrationNO = registration_no
        // eslint-disable-next-line camelcase
        state.contactName = contract_name
        // eslint-disable-next-line camelcase
        state.contactPhone = phone_number
        // eslint-disable-next-line camelcase
        state.contactEmail = email_address
        // eslint-disable-next-line camelcase
        if (reseller_kyc_file) {
          const {
          // eslint-disable-next-line camelcase
            id_front_pic_url, id_back_pic_url, proof_of_address_pic_url, business_registration_pic_url, annual_return_pic_url, certificate_of_incorporation_pic_url, memorandum_and_articles_of_association_pic_url, dd_form_url,
          // eslint-disable-next-line camelcase
          } = reseller_kyc_file
          // eslint-disable-next-line camelcase
          state.PositivePassportUrl = id_front_pic_url
          // eslint-disable-next-line camelcase
          state.BackPassportUrl = id_back_pic_url
          // eslint-disable-next-line camelcase
          state.ProofOfAddressUrl = proof_of_address_pic_url
          // eslint-disable-next-line camelcase
          state.BusinessRegistrationUrl = business_registration_pic_url
          // eslint-disable-next-line camelcase
          state.AnnualReturnUrl = annual_return_pic_url
          // eslint-disable-next-line camelcase
          state.CertificateUrl = certificate_of_incorporation_pic_url
          // eslint-disable-next-line camelcase
          state.MemorandumUrl = memorandum_and_articles_of_association_pic_url
          // eslint-disable-next-line camelcase
          state.DDFormUrl = dd_form_url
          changeUrlToStream(dd_form_url)
        }
        // eslint-disable-next-line camelcase
        if (reseller_bank_info) {
          const {
          // eslint-disable-next-line camelcase
            bank_name, bank_address, account_name, account_number, bab_number,
          // eslint-disable-next-line camelcase
          } = reseller_bank_info
          // eslint-disable-next-line camelcase
          state.bankName = bank_name
          // eslint-disable-next-line camelcase
          state.bankAddress = bank_address
          // eslint-disable-next-line camelcase
          state.accountName = account_name
          // eslint-disable-next-line camelcase
          state.accountNumber = account_number
          state.swift = reseller_bank_info.swift
          state.iban = reseller_bank_info.iban
          // eslint-disable-next-line camelcase
          state.bsbNumber = bab_number
          state.aba = reseller_bank_info.aba
        }
      } else {
        showToast(proxy, 'Warning', msg)
      }
    }).catch(err => {
      state.isKYCInfoLoading = false
      showToast(proxy, 'Warning', `fail with ${err}`)
    })
  }
  const approve = () => {
    state.isKYCInfoLoading = true
    store.dispatch('resellerKyc/kycApprovalPass', { id: state.kycId }).then(res => {
      state.isKYCInfoLoading = false
      const { code, msg } = res.data
      if (code === '10000') {
        showToast(proxy, 'Success', 'Approve Success')
        setTimeout(() => {
          proxy.$router.go(0)
        }, 2000)
      } else {
        showToast(proxy, 'Warning', msg)
      }
    }).catch(err => {
      state.isKYCInfoLoading = false
      showToast(proxy, 'Warning', `fail with ${err}`)
    })
  }
  const reject = () => {
    state.isKYCInfoLoading = true
    store.dispatch('resellerKyc/kycApprovalReject', { id: state.kycId }).then(res => {
      state.isKYCInfoLoading = false
      const { code, msg } = res.data
      if (code === '10000') {
        showToast(proxy, 'Success', 'Reject Success')
        setTimeout(() => {
          proxy.$router.go(0)
        }, 2000)
      } else {
        showToast(proxy, 'Warning', msg)
      }
    }).catch(err => {
      state.isKYCInfoLoading = false
      showToast(proxy, 'Warning', `fail with ${err}`)
    })
  }
  return {
    isKYCInfoLoading,
    isKycDisabled,
    kycId,
    infoCountry,
    infoStatus,
    infoType,

    idNumber,
    registrationNO,
    PositivePassportUrl,
    BackPassportUrl,
    ProofOfAddressUrl,
    DDFormUrl,
    CertificateUrl,
    MemorandumUrl,
    BusinessRegistrationUrl,
    AnnualReturnUrl,

    contactName,
    contactPhone,
    contactEmail,
    bankName,
    accountName,
    accountNumber,
    bankAddress,
    swift,
    iban,
    bsbNumber,
    aba,

    kycInfoInit,
    approve,
    reject,
  }
}
export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const {
      isKYCInfoLoading, isKycDisabled, kycId, infoCountry, infoStatus, infoType, idNumber, registrationNO, PositivePassportUrl, BackPassportUrl, ProofOfAddressUrl, DDFormUrl, CertificateUrl, MemorandumUrl, BusinessRegistrationUrl, AnnualReturnUrl, contactName, contactPhone, contactEmail, bankName, accountName, accountNumber, bankAddress, swift, iban, bsbNumber, aba, kycInfoInit, approve, reject,
    } = useKycInfo(proxy)
    watch(() => proxy.tabIdx, value => {
      if (value === '2') {
        proxy.$router.push({ name: 'reseller-info-detail', query: { reseller_id: proxy.resellerId, from: 'kycreview' } }, () => {}, () => {})
        kycInfoInit()
      }
    })
    onMounted(() => {
      if (proxy.tabIdx === '2') {
        kycInfoInit()
      }
    })
    return {
      isKYCInfoLoading,
      isKycDisabled,
      kycId,
      infoCountry,
      infoStatus,
      infoType,

      idNumber,
      registrationNO,
      PositivePassportUrl,
      BackPassportUrl,
      ProofOfAddressUrl,
      DDFormUrl,
      CertificateUrl,
      MemorandumUrl,
      BusinessRegistrationUrl,
      AnnualReturnUrl,

      contactName,
      contactPhone,
      contactEmail,
      bankName,
      accountName,
      accountNumber,
      bankAddress,
      swift,
      iban,
      bsbNumber,
      aba,

      kycInfoInit,
      approve,
      reject,
    }
  },
  components: {
    BCard, BRow, BCol, BCardText, BButton, BFormGroup, BFormInput, BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    tabIdx: {
      type: String,
      default: '',
    },
    resellerId: {
      type: String,
      default: '',
    },
  },
})
</script>
<style lang="scss" scoped>
.reseller-kyc {
  overflow: hidden;
  .information-type {
    display: inline-block;
    width: 100px;
    text-align: right;
    margin-right: 10px;
  }
  .files-list {
    display: flex;
    .file {
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #cbc3c3;
      float: left;
      flex: 1;
    }
  }
  .preview-docx {
    line-height: 38px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
