<template>
  <div>
    <b-overlay
      :show="isBuyinRateLoading"
      spinner-variant="primary"
      variant="light"
      opacity="0.7"
      rounded="sm"
    >
      <b-card>
        <b-card-header>
          <b-card-title>Reseller Buyin Fee Config</b-card-title>
          <b-button variant="primary" class="mr-4" @click="createRate">
            Create
          </b-button>
        </b-card-header>
        <b-table
          ref="refBuyinRateTable"
          class="position-relative"
          :items="tableItems"
          :per-page="perPage"
          :current-page="currentPage"
          responsive
          :fields="tableColumns"
          primary-key="uid"
          show-empty
          empty-text="No matching records found"
        >
          <!-- Column: Status -->
          <template #cell(status)="data">
            <div class="text-nowrap">
              <b-form-checkbox
                :checked="data.item.status === 'ACTIVE'"
                name="check-button"
                switch
                inline
                @change="changeBuyinStatus(data.item.id)"
              />
            </div>
          </template>
          <!-- Column: fee_detail -->
          <template #cell(fee_detail)="data">
            <div class="text-nowrap">
              {{
                data.item.fee_detail
                  ? `${data.item.fee_detail.split(',')[2]} ${
                    data.item.fee_detail.split(',')[3]
                  }`
                  : ''
              }}
            </div>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <div class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="editRate(data.item)"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Edit</span>
              </b-button>
            </div>
          </template>
          <!-- Column: History -->
          <!-- 显示历史费率的配置 -->
          <template #cell(history)="data">
            <div class="text-nowrap">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="showHistory(data.item)"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">View</span>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <!-- Create/Edit modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="buyin-rate-modal"
      :title="buyinRateTitle"
      :ok-title="buyinRateOkTitle"
      cancel-variant="outline-secondary"
      size="lg"
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-overlay
        :show="isCreateRateLoading"
        spinner-variant="primary"
        variant="light"
        opacity="0.7"
        rounded="sm"
      >
        <validation-observer ref="createBuyinRateRules">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Name" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="configName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name"
                      autocomplete="off"
                      oninput="if(value.length > 32)value = value.slice(0, 32)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Type" label-for="type">
                  <validation-provider
                    #default="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      id="type"
                      v-model="configType"
                      :state="errors.length > 0 ? false : null"
                      :options="typeOptions"
                      :clearable="false"
                      class="w-100"
                      placeholder="Type"
                      :reduce="(val) => val.value"
                      @input="configPaymentMethod = ''"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="configType !== 'Payout-Success'" cols="12" md="6">
                <b-form-group label="Payment Method" label-for="paymentMethod">
                  <validation-provider
                    #default="{ errors }"
                    name="Payment Method"
                    rules="required"
                  >
                    <v-select
                      id="paymentMethod"
                      v-model="configPaymentMethod"
                      :state="errors.length > 0 ? false : null"
                      :options="methodOptions"
                      :clearable="false"
                      class="w-100"
                      placeholder="Payment Method"
                      :reduce="(val) => val.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="configType === 'Payout-Success'" cols="12" md="6">
                <b-form-group label="Payment Method" label-for="paymentMethod">
                  <validation-provider
                    #default="{ errors }"
                    name="Payment Method"
                    rules="required"
                  >
                    <v-select
                      id="paymentMethod"
                      v-model="configPaymentMethod"
                      :state="errors.length > 0 ? false : null"
                      :options="payoutMethodOptions"
                      :clearable="false"
                      class="w-100"
                      placeholder="Payment Method"
                      :reduce="(val) => val.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Currency" label-for="currency">
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    rules="required"
                  >
                    <v-select
                      id="currency"
                      v-model="configCurrency"
                      :state="errors.length > 0 ? false : null"
                      :options="currencyOptions"
                      :clearable="false"
                      class="w-100"
                      placeholder="Currency"
                      :reduce="(val) => val.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Country" label-for="country">
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <v-select
                      id="country"
                      v-model="configCountry"
                      :state="errors.length > 0 ? false : null"
                      :options="countryOptions"
                      :clearable="false"
                      class="w-100"
                      placeholder="Country"
                      :reduce="(val) => val.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Formula" label-for="formula">
                  <validation-provider
                    #default="{ errors }"
                    name="Formula"
                    rules="required"
                  >
                    <v-select
                      id="formula"
                      v-model="configFormula"
                      :state="errors.length > 0 ? false : null"
                      :options="formulaOptions"
                      :clearable="false"
                      class="w-100"
                      placeholder="Formula"
                      :reduce="(val) => val.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Lower Bound (Set -1 to mark as no-limit)"
                  label-for="LowerBound"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lower Bound"
                    rules="required"
                  >
                    <b-form-input
                      id="LowerBound"
                      v-model="lowerBound"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Lower Bound (Set -1 to mark as no-limit)"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Upper Bound (Set -1 to mark as no-limit)"
                  label-for="UpperBound"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Upper Bound"
                    rules="required"
                  >
                    <b-form-input
                      id="UpperBound"
                      v-model="upperBound"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Upper Bound (Set -1 to mark as no-limit)"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Value" label-for="value">
                  <validation-provider
                    #default="{ errors }"
                    name="Value"
                    rules="required"
                  >
                    <b-form-input
                      id="value"
                      v-model="feeValue"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Value"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Unit" label-for="unit">
                  <validation-provider
                    #default="{ errors }"
                    name="Unit"
                    rules="required"
                  >
                    <v-select
                      id="unit"
                      v-model="unit"
                      :state="errors.length > 0 ? false : null"
                      :options="unitOptions"
                      :clearable="false"
                      class="w-100"
                      placeholder="Unit"
                      :reduce="(val) => val.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Lower Limit (Set -1 to mark as no-limit)"
                  label-for="LowerLimit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lower Limit"
                    rules="required"
                  >
                    <b-form-input
                      id="LowerLimit"
                      v-model="lowerLimit"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Lower Limit (Set -1 to mark as no-limit)"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Upper Limit (Set -1 to mark as no-limit)"
                  label-for="UpperLimit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Upper Limit"
                    rules="required"
                  >
                    <b-form-input
                      id="UpperLimit"
                      v-model="upperLimit"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Upper Limit (Set -1 to mark as no-limit)"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Status" label-for="status">
                  <validation-provider
                    #default="{ errors }"
                    name="Status"
                    rules="required"
                  >
                    <v-select
                      id="status"
                      v-model="configStatus"
                      :state="errors.length > 0 ? false : null"
                      :options="statusOption"
                      :clearable="false"
                      class="w-100"
                      placeholder="Status"
                      :reduce="(val) => val.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-group label="Descp" label-for="descp">
                  <validation-provider
                    #default="{ errors }"
                    name="Descp"
                    rules="required"
                  >
                    <b-form-textarea
                      id="descp"
                      v-model="configDescp"
                      placeholder="Descp"
                      rows="2"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-overlay>
    </b-modal>
    <!-- View modal -->
    <b-modal
      ref="history-modal"
      title="History"
      cancel-variant="outline-secondary"
      size="xl"
      hide-footer
    >
      <b-overlay
        :show="isHistoryLoading"
        spinner-variant="primary"
        variant="light"
        opacity="0.7"
        rounded="sm"
      >
        <b-table
          :items="historyTableItems"
          :per-page="historyPerPage"
          :current-page="historyCurrentPage"
          responsive
          :fields="historyTableColumns"
          primary-key="uid"
          show-empty
          empty-text="No matching records found"
        >
          <!-- Column: fee_detail -->
          <template #cell(fee_detail)="data">
            <div class="text-nowrap">
              {{
                data.item.fee_detail
                  ? `${data.item.fee_detail.split(',')[2]} ${
                    data.item.fee_detail.split(',')[3]
                  }`
                  : ''
              }}
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ historyDataMeta.from }} to
                {{ historyDataMeta.to }} of
                {{ historyDataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="historyCurrentPage"
                :total-rows="historyTotal"
                :per-page="historyPerPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BTable,
  BPagination,
  BFormCheckbox,
  VBModal,
  BFormInput,
  BFormTextarea,
  BOverlay,
} from 'bootstrap-vue'
import {
  defineComponent,
  reactive,
  getCurrentInstance,
  computed,
  toRefs,
  watch,
  onMounted,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'

const useBuyinConfigTable = proxy => {
  const state = reactive({
    isBuyinRateLoading: false,
    refBuyinRateTable: '',
    tableItems: [],
    perPage: 10,
    currentPage: 1,
    total: 0,
    tableColumns: [
      // { key: 'id', label: 'id' },
      { key: 'config_name', label: 'name' },
      { key: 'country', label: 'country' },
      { key: 'currency', label: 'currency' },
      { key: 'descp', label: 'descp' },
      { key: 'fee_detail', label: 'fee detail' },
      { key: 'formula', label: 'formula' },
      { key: 'payment_method', label: 'method' },
      { key: 'type', label: 'type' },
      // { key: 'create_time', label: 'create time' },
      // { key: 'update_time', label: 'update time' },
      { key: 'status', label: 'status' },
      { key: 'action', label: 'Action' },
      { key: 'history', label: 'History' },
    ],
    countryOptions: [],
    currencyOptions: [],
    unitOptions: [],
    methodOptions: [],
    payoutMethodOptions: [],
    typeOptions: [],
    formulaOptions: [
      { label: 'formula1', value: 'formula1' },
      { label: 'formula2', value: 'formula2' },
      { label: 'formula3', value: 'formula3' },
      { label: 'formula4', value: 'formula4' },
    ],
    statusOption: [
      { label: 'ACTIVE', value: 'ACTIVE' },
      { label: 'INACTIVE', value: 'INACTIVE' },
    ],
  })
  const {
    isBuyinRateLoading,
    refBuyinRateTable,
    tableItems,
    perPage,
    currentPage,
    total,
    tableColumns,
    countryOptions,
    currencyOptions,
    unitOptions,
    methodOptions,
    payoutMethodOptions,
    typeOptions,
    formulaOptions,
    statusOption,
  } = toRefs(state)
  const dataMeta = computed(() => ({
    from: state.perPage * (state.currentPage - 1) + (state.total ? 1 : 0),
    to:
      state.total - state.currentPage * 10 > 0
        ? state.currentPage * 10
        : state.total,
    of: state.total,
  }))
  // 弹框下拉数据
  const fetchResellerBuyinOptions = () => {
    store.dispatch('resellerKyc/fetchResellerBuyinOptions', {}).then(res => {
      const { code, msg, data } = res.data
      if (code === '10000') {
        const {
          countries, currencies, methods, type,
        } = data
        state.countryOptions = countries.map(item => ({
          label: item.region_name,
          value: item.region_name,
        }))
        state.currencyOptions = currencies.map(item => ({
          label: item.code,
          value: item.code,
        }))
        state.unitOptions = currencies
          .map(item => ({ label: item.code, value: item.code }))
          .concat({ label: '%', value: '%' })
        state.methodOptions = methods.map(item => ({
          label: item.type_name,
          value: item.type_name,
        }))
        state.payoutMethodOptions = [
          ...new Set(data['payout-method']),
        ].map(item => ({ label: item, value: item }))
        state.typeOptions = type.map(item => ({ label: item, value: item })) // Payout-Success
      } else {
        console.log(msg)
      }
    })
  }
  const fetchBuyinRateConfig = () => {
    state.isBuyinRateLoading = true
    store
      .dispatch('resellerKyc/fetchBuyinRateConfig', {
        reseller_id: proxy.resellerId,
      })
      .then(res => {
        state.isBuyinRateLoading = false
        const { code, msg, data } = res.data
        if (code === '10000') {
          state.tableItems = data
          state.total = data.length
        } else {
          showToast(proxy, 'Warning', msg)
        }
      })
    fetchResellerBuyinOptions()
  }
  const changeBuyinStatus = value => {
    state.isBuyinRateLoading = true
    store
      .dispatch('resellerKyc/buyinRateConfigStatusUpdate', { id: value })
      .then(res => {
        state.isBuyinRateLoading = false
        const { code, msg } = res.data
        if (code === '10000') {
          showToast(proxy, 'Success', 'Success')
          setTimeout(() => {
            fetchBuyinRateConfig()
          }, 2000)
        } else {
          showToast(proxy, 'Warning', msg)
        }
      })
  }
  return {
    isBuyinRateLoading,
    refBuyinRateTable,
    tableItems,
    perPage,
    currentPage,
    total,
    tableColumns,
    dataMeta,
    fetchBuyinRateConfig,
    changeBuyinStatus,
    countryOptions,
    currencyOptions,
    unitOptions,
    methodOptions,
    payoutMethodOptions,
    typeOptions,
    formulaOptions,
    statusOption,
  }
}
const useBuyinConfigModal = (proxy, fetchBuyinRateConfig) => {
  const state = reactive({
    buyinRateTitle: 'New Commission Fee Config',
    buyinRateOkTitle: 'Create',
    isCreateRateLoading: false,

    configName: '',
    configType: '',
    configPaymentMethod: '',
    configCurrency: '',
    configCountry: '',
    configFormula: '',
    lowerBound: '',
    upperBound: '',
    feeValue: '',
    unit: '',
    lowerLimit: '',
    upperLimit: '',
    configStatus: '',
    configDescp: '',

    configId: '',
  })
  const {
    buyinRateTitle,
    buyinRateOkTitle,
    isCreateRateLoading,
    configName,
    configType,
    configPaymentMethod,
    configCurrency,
    configCountry,
    configFormula,
    lowerBound,
    upperBound,
    feeValue,
    unit,
    lowerLimit,
    upperLimit,
    configStatus,
    configDescp,
  } = toRefs(state)
  const createRate = () => {
    state.buyinRateTitle = 'New Commission Fee Config'
    state.buyinRateOkTitle = 'Create'

    state.configName = ''
    state.configType = ''
    state.configPaymentMethod = ''
    state.configCurrency = ''
    state.configCountry = ''
    state.configFormula = ''
    state.lowerBound = ''
    state.upperBound = ''
    state.feeValue = ''
    state.unit = ''
    state.lowerLimit = ''
    state.upperLimit = ''
    state.configStatus = ''
    state.configDescp = ''
    state.configId = ''

    proxy.$refs['buyin-rate-modal'].show()
  }
  const initFeeConfig = data => {
    state.configName = data.config_name
    state.configType = data.type
    state.configPaymentMethod = data.payment_method
    state.configCurrency = data.currency
    state.configCountry = data.country
    state.configFormula = data.formula
    state.configStatus = data.status
    state.configDescp = data.descp
    // eslint-disable-next-line no-multi-assign
    state.configId = data.id;
    [
      state.lowerBound,
      state.upperBound,
      state.feeValue,
      state.unit,
      state.lowerLimit,
      state.upperLimit,
    ] = data.fee_detail.split(',')
    if (state.lowerBound === '~') {
      state.lowerBound = '-1'
    }
    if (state.upperBound === '~') {
      state.upperBound = '-1'
    }
    if (state.lowerLimit === '~') {
      state.lowerLimit = '-1'
    }
    if (state.upperLimit === '~') {
      state.upperLimit = '-1'
    }
  }
  const editRate = data => {
    state.buyinRateTitle = 'Edit Commission Fee Config'
    state.buyinRateOkTitle = 'Confirm'
    proxy.$refs['buyin-rate-modal'].show()
    initFeeConfig(data)
  }
  const resetModal = () => {}
  const handleSubmit = () => {
    proxy.$refs.createBuyinRateRules.validate().then(success => {
      if (success) {
        const params = {
          config_name: state.configName,
          type: state.configType,
          payment_method: state.configPaymentMethod,
          country: state.configCountry,
          formula: state.configFormula,
          status: state.configStatus,
          descp: state.configDescp,
          reseller_id: proxy.resellerId,
          currency: state.configCurrency,
        }
        if (state.lowerBound < 0) {
          state.lowerBound = '~'
        }
        if (state.upperBound < 0) {
          state.upperBound = '~'
        }
        if (state.lowerLimit < 0) {
          state.lowerLimit = '~'
        }
        if (state.upperLimit < 0) {
          state.upperLimit = '~'
        }
        params.fee_detail = `${state.lowerBound},${state.upperBound},${state.feeValue},${state.unit},${state.lowerLimit},${state.upperLimit}`
        if (state.configId) {
          params.id = state.configId
          state.isCreateRateLoading = true
          store
            .dispatch('resellerKyc/updateBuyinRateConfig', params)
            .then(res => {
              state.isCreateRateLoading = false
              const { code, msg } = res.data
              if (code === '10000') {
                showToast(proxy, 'Success', 'Update Success')
                proxy.$nextTick(() => {
                  proxy.$refs['buyin-rate-modal'].hide()
                })
                setTimeout(() => {
                  fetchBuyinRateConfig()
                }, 100)
              } else {
                showToast(proxy, 'Warning', msg)
              }
            })
        } else {
          state.isCreateRateLoading = true
          store
            .dispatch('resellerKyc/addBuyinRateConfig', params)
            .then(res => {
              state.isCreateRateLoading = false
              const { code, msg } = res.data
              if (code === '10000') {
                showToast(proxy, 'Success', 'Add Success')
                proxy.$nextTick(() => {
                  proxy.$refs['buyin-rate-modal'].hide()
                })
                setTimeout(() => {
                  fetchBuyinRateConfig()
                }, 100)
              } else {
                showToast(proxy, 'Warning', msg)
              }
            })
        }
      }
    })
  }
  const handleOk = bvModalEvt => {
    bvModalEvt.preventDefault()
    handleSubmit()
  }
  return {
    buyinRateTitle,
    buyinRateOkTitle,
    isCreateRateLoading,

    configName,
    configType,
    configPaymentMethod,
    configCurrency,
    configCountry,
    configFormula,
    lowerBound,
    upperBound,
    feeValue,
    unit,
    lowerLimit,
    upperLimit,
    configStatus,
    configDescp,

    createRate,
    editRate,
    resetModal,
    handleSubmit,
    handleOk,
  }
}
const useShowHistory = proxy => {
  const state = reactive({
    isHistoryLoading: false,
    historyTableItems: [],
    historyPerPage: 10,
    historyCurrentPage: 1,
    historyTotal: 0,
    historyTableColumns: [
      { key: 'config_name', label: 'name' },
      { key: 'country', label: 'country' },
      { key: 'currency', label: 'currency' },
      { key: 'descp', label: 'descp' },
      { key: 'fee_detail', label: 'fee detail' },
      { key: 'formula', label: 'formula' },
      { key: 'payment_method', label: 'method' },
      { key: 'type', label: 'type' },
      { key: 'create_time', label: 'update time' },
    ],
  })
  const {
    isHistoryLoading,
    historyTableItems,
    historyPerPage,
    historyCurrentPage,
    historyTotal,
    historyTableColumns,
  } = toRefs(state)
  const historyDataMeta = computed(() => ({
    from:
      state.historyPerPage * (state.historyCurrentPage - 1)
      + (state.historyTotal ? 1 : 0),
    to:
      state.historyTotal - state.historyCurrentPage * 10 > 0
        ? state.historyCurrentPage * 10
        : state.historyTotal,
    of: state.historyTotal,
  }))
  const showHistory = history => {
    proxy.$refs['history-modal'].show()
    state.isHistoryLoading = true
    store
      .dispatch('resellerKyc/buyinRateConfigHistory', {
        page_num: state.historyCurrentPage,
        page_size: state.historyPerPage,
        id: history.id,
      })
      .then(res => {
        state.isHistoryLoading = false
        const { code, msg, data } = res.data
        if (code === '10000') {
          state.historyTableItems = data.list
          state.historyTotal = data.list.length
        } else if (code === '80050') {
          state.historyTableItems = []
          state.historyTotal = 0
        } else {
          showToast(proxy, 'Warning', msg)
        }
      })
  }
  return {
    isHistoryLoading,
    historyTableItems,
    historyPerPage,
    historyCurrentPage,
    historyTotal,
    historyTableColumns,
    historyDataMeta,
    showHistory,
  }
}
export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const {
      isBuyinRateLoading,
      refBuyinRateTable,
      tableItems,
      perPage,
      currentPage,
      total,
      tableColumns,
      dataMeta,
      fetchBuyinRateConfig,
      changeBuyinStatus,
      countryOptions,
      currencyOptions,
      unitOptions,
      methodOptions,
      payoutMethodOptions,
      typeOptions,
      formulaOptions,
      statusOption,
    } = useBuyinConfigTable(proxy)
    const {
      buyinRateTitle,
      buyinRateOkTitle,
      isCreateRateLoading,

      configName,
      configType,
      configPaymentMethod,
      configCurrency,
      configCountry,
      configFormula,
      lowerBound,
      upperBound,
      feeValue,
      unit,
      lowerLimit,
      upperLimit,
      configStatus,
      configDescp,
      createRate,
      editRate,
      resetModal,
      handleSubmit,
      handleOk,
    } = useBuyinConfigModal(proxy, fetchBuyinRateConfig)
    const {
      isHistoryLoading,
      historyTableItems,
      historyPerPage,
      historyCurrentPage,
      historyTotal,
      historyTableColumns,
      historyDataMeta,
      showHistory,
    } = useShowHistory(proxy)
    watch(
      () => proxy.tabIdx,
      value => {
        if (value === '1') {
          proxy.$router.push(
            {
              name: 'reseller-info-detail',
              query: {
                reseller_id: proxy.resellerId,
                from: 'buyin-rate-config',
              },
            },
            () => {},
            () => {},
          )
          fetchBuyinRateConfig()
        }
      },
    )
    onMounted(() => {
      if (proxy.tabIdx === '1') {
        fetchBuyinRateConfig()
      }
    })
    return {
      createRate,
      isBuyinRateLoading,
      refBuyinRateTable,
      tableItems,
      perPage,
      currentPage,
      total,
      tableColumns,
      editRate,
      dataMeta,
      changeBuyinStatus,

      countryOptions,
      currencyOptions,
      unitOptions,
      methodOptions,
      payoutMethodOptions,
      typeOptions,
      formulaOptions,
      statusOption,
      // modal
      buyinRateTitle,
      buyinRateOkTitle,
      isCreateRateLoading,

      configName,
      configType,
      configPaymentMethod,
      configCurrency,
      configCountry,
      configFormula,
      lowerBound,
      upperBound,
      feeValue,
      unit,
      lowerLimit,
      upperLimit,
      configStatus,
      configDescp,

      resetModal,
      handleSubmit,
      handleOk,
      // validate 验证规则
      required,
      email,
      url,

      isHistoryLoading,
      historyTableItems,
      historyPerPage,
      historyCurrentPage,
      historyTotal,
      historyTableColumns,
      historyDataMeta,
      showHistory,
    }
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BPagination,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BOverlay,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    tabIdx: {
      type: String,
      default: '',
    },
    resellerId: {
      type: String,
      default: '',
    },
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
