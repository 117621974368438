<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group
          label="Reseller Rev Share Formula"
          label-for="shareFormula"
        >
          <v-select
            id="shareFormula"
            v-model="shareFormula"
            :disabled="isEdit"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            :options="shareFormulaOptions"
            :reduce="option => option.value"
            placeholder="Reseller Rev Share Formula"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" />
    </b-row>
    <b-table
      ref="refSettlementTable"
      class="position-relative"
      :items="tableItems"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      :fields="tableColumns"
      primary-key="uid"
      show-empty
      empty-text="No matching records found"
    >
      <!-- Column: Status -->
      <template #cell(status)="data">
        <div class="text-nowrap">
          <b-form-checkbox
            :disabled="isEdit"
            :checked="Boolean(Number(data.item.status))"
            name="check-button"
            switch
            inline
            @change="changeProfitStatus(data.item.app_id)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  defineComponent, ref, reactive, computed, onMounted, getCurrentInstance, watch,
} from '@vue/composition-api'
import {
  BRow, BCol, BFormGroup, BFormInput, BTable, BPagination, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { showToast } from '@/libs/tool'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const shareFormula = ref('')
    const shareFormulaOptions = reactive([
      { label: 'Formula 1', value: 'formula1' },
      { label: 'Formula 2', value: 'formula2' },
      { label: 'Formula 3', value: 'formula3' },
      { label: 'Formula 4', value: 'formula4' },
    ])
    // table buyinRate
    const refSettlementTable = ref('')
    const tableItems = ref([])
    const perPage = ref(10)
    const currentPage = ref(1)
    const total = ref(0)
    const tableColumns = reactive([
      { key: 'merchant_name', label: 'Merchant Name' },
      { key: 'merchant_no', label: 'Merchant ID' },
      { key: 'app_name', label: 'App name' },
      { key: 'app_id', label: 'App ID' },
      { key: 'region_name', label: 'Region' },
      // { key: 'formula', label: 'Rev share Formula' },
      // { key: 'descp', label: 'Descp' },
      { key: 'status', label: 'Status' },
    ])
    const dataMeta = computed(() => ({
      from: perPage.value * (currentPage.value - 1) + (total.value ? 1 : 0),
      to: total.value - currentPage.value * 10 > 0 ? currentPage.value * 10 : total.value,
      of: total.value,
    }))
    const isEdit = ref(false)
    const fetchRevenueListApp = () => {
      // const data1 = [
      //   {
      //     merchant_no: '102320170519',
      //     app_id: '16548508058518378',
      //     app_name: 'Tinder MY',
      //     region: 'MYS',
      //     region_name: 'Malaysia',
      //     contact: 'liudi@smile.one',
      //     contact_email: 'liudi@smile.one',
      //     phone: '1111111',
      //     report_email: 'liudi@smile.one',
      //     index_url: 'liudi@smile.one',
      //     mock_index_url: '',
      //     mock_app_name: '',
      //     icon_url: '',
      //     industry: 'Game',
      //     review_level: 1,
      //     status: '1',
      //     create_time: '2022-06-10 08:46:45',
      //     update_time: '2022-06-10 09:06:36',
      //     app_name_short: 'Tinder MY',
      //   },
      //   {
      //     merchant_no: '1634625786211609',
      //     app_id: '16346345291860028',
      //     app_name: 'Fin Academy',
      //     region: 'BRA',
      //     region_name: 'Brazil',
      //     contact: 'Finance',
      //     contact_email: 'finance@kingsetholdings.com',
      //     phone: '35797781054',
      //     report_email: 'finance@kingsetholdings.com',
      //     index_url: 'https://finacademy.io/',
      //     mock_index_url: '',
      //     mock_app_name: '测试商户',
      //     icon_url: '',
      //     status: '1',
      //     create_time: '2021-10-19 09:08:49',
      //     update_time: '2021-11-18 12:18:37',
      //     app_name_short: 'FA',
      //   },
      // ]
      // tableItems.value = data1
      // total.value = data1.length
      store.dispatch('resellerKyc/resellerRevenueListApp', { reseller_id: proxy.resellerId }).then(res => {
        const { code, msg, data } = res.data
        if (code === '10000') {
          if (data.isEdit) {
            isEdit.value = data.isEdit
            proxy.$parent.revenueId = data.list[0].revenue_id
            proxy.$swal({
              title: 'Tips',
              text: 'Please finish your previous settlement!',
              icon: 'info',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          tableItems.value = data.list
          total.value = data.list.length
          if (data.list.length > 0) {
            shareFormula.value = data.list[0].formula
          }
        } else {
          showToast(proxy, 'Warning', msg)
        }
      })
    }
    const changeProfitStatus = appId => {
      tableItems.value.forEach(item => {
        if (item.app_id === appId) {
          // eslint-disable-next-line no-param-reassign
          item.status = item.status === '1' ? '0' : '1'
        }
      })
    }
    const saveProfitSharing = callback => {
      if (proxy.$parent.revenueId) {
        callback(proxy.$parent.revenueId)
        return
      }
      if (shareFormula.value === '') {
        showToast(proxy, 'Warning', 'Please select one formula at least')
        callback(false)
      } else {
        store.dispatch('resellerKyc/resellerRevenueConfirmStep1', { reseller_id: proxy.resellerId, formula: shareFormula.value, tableItems: tableItems.value }).then(res => {
          const { code, msg, data } = res.data
          if (code === '10000') {
            callback(data)
          } else {
            callback(false)
            showToast(proxy, 'Warning', msg)
          }
        }).catch(() => {
          callback(false)
        })
      }
    }
    onMounted(() => {
      if (proxy.wizardIndex === 0) {
        // 获取分润结算配置
        fetchRevenueListApp()
      }
    })
    watch(() => proxy.wizardIndex, value => {
      if (value === 0) {
        // 获取分润结算配置
        fetchRevenueListApp()
      }
    })
    return {
      shareFormula,
      shareFormulaOptions,
      refSettlementTable,
      tableItems,
      perPage,
      currentPage,
      total,
      tableColumns,
      dataMeta,

      isEdit,
      changeProfitStatus,
      saveProfitSharing,
    }
  },
  components: {
    BRow, BCol, BFormGroup, BFormInput, BTable, BPagination, BFormCheckbox, BButton, vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    resellerId: {
      type: String,
      default: '',
    },
    wizardIndex: {
      type: Number,
      default: 0,
    },
    revenueId: {
      type: String,
      default: '',
    },
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
