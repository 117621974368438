<template>
  <b-overlay
    :show="isBasicInfoLoading"
    spinner-variant="primary"
    variant="light"
    opacity="0.7"
    rounded="sm"
  >
    <b-card title="Basic Info">
      <b-row>
        <b-col cols="12" md="4">
          <b-row>
            <b-col cols="12" md="6" class="text-right">
              Reseller name：
            </b-col>
            <b-col cols="12" md="6">
              {{ resellerName }}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="4">
          <b-row>
            <b-col cols="12" md="6" class="text-right">
              Master Reseller name：
            </b-col>
            <b-col cols="12" md="6">
              {{ masterResellerName }}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="4">
          <b-row>
            <b-col cols="12" md="6" class="text-right">
              Type：
            </b-col>
            <b-col cols="12" md="6">
              {{ infoType }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" md="4">
          <b-row>
            <b-col cols="12" md="6" class="text-right">
              Contact Phone：
            </b-col>
            <b-col cols="12" md="6">
              {{ infoPhone }}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="4">
          <b-row>
            <b-col cols="12" md="6" class="text-right">
              Contact Email：
            </b-col>
            <b-col cols="12" md="6">
              {{ infoEmail }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    <!-- <b-row class="mt-2">
      <b-col cols="12" md="4">
        <b-row>
          <b-col cols="12" md="6" class="text-right">
            Contact Name：
          </b-col>
          <b-col cols="12" md="6">
            1
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="4">
        <b-row>
          <b-col cols="12" md="6" class="text-right">
            Country：
          </b-col>
          <b-col cols="12" md="6">
            2
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="4">
        <b-row>
          <b-col cols="12" md="6" class="text-right">
            Address：
          </b-col>
          <b-col cols="12" md="6">
            1
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="4">
        <b-row>
          <b-col cols="12" md="6" class="text-right">
            Website：
          </b-col>
          <b-col cols="12" md="6">
            3
          </b-col>
        </b-row>
      </b-col>
    </b-row> -->
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import {
  defineComponent, getCurrentInstance, watch, onMounted, reactive, toRefs,
} from '@vue/composition-api'
import store from '@/store'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      isBasicInfoLoading: false,
      resellerName: '',
      infoType: '',
      infoPhone: '',
      infoEmail: '',
      masterResellerName: '',
    })
    const {
      isBasicInfoLoading, resellerName, infoType, infoPhone, infoEmail, masterResellerName,
    } = toRefs(state)
    const fetchBasicInfo = () => {
      state.isBasicInfoLoading = true
      store.dispatch('resellerKyc/fetchBasicInfo', { reseller_id: proxy.resellerId }).then(res => {
        state.isBasicInfoLoading = false
        const { code, data, msg } = res.data
        if (code === '10000') {
          // eslint-disable-next-line camelcase
          const {
            // eslint-disable-next-line camelcase
            reseller_name, type, email, phone, top_reseller_name,
          } = data
          // eslint-disable-next-line camelcase
          state.resellerName = reseller_name
          state.infoType = type
          state.infoPhone = phone
          state.infoEmail = email
          // eslint-disable-next-line camelcase
          state.masterResellerName = top_reseller_name
        } else {
          console.log(msg)
        }
      })
    }
    watch(() => proxy.tabIdx, value => {
      if (value === '0') {
        proxy.$router.push({ name: 'reseller-info-detail', query: { reseller_id: proxy.resellerId, from: '' } }, () => {}, () => {})
        fetchBasicInfo()
      }
    })
    onMounted(() => {
      if (proxy.tabIdx === '0') {
        fetchBasicInfo()
      }
    })
    return {
      isBasicInfoLoading, resellerName, infoType, infoPhone, infoEmail, masterResellerName,
    }
  },
  components: {
    BCard, BRow, BCol, BFormGroup, BOverlay,
  },
  props: {
    tabIdx: {
      type: String,
      default: '',
    },
    resellerId: {
      type: String,
      default: '',
    },
  },
})
</script>
